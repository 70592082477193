<template>
  <v-container class="alert-box-wrapper">
    <v-alert class="alert-box" :class="{ 'show-alert': isError }" type="error" :value="true">{{ errorMessage }}</v-alert>
    <v-alert class="alert-box" :class="{ 'show-alert': isSuccess }" type="success" :value="true">{{ successMessage }}</v-alert>
  </v-container>

  <v-container class="forgot-password-page">
    <v-card class="forgot-password-form" outlined>
      <v-card-title class="text-h5">Reset hasła</v-card-title>
      <v-card-text>
        <v-alert v-if="message" type="success" :value="true">{{ message }}</v-alert>

        <!-- Email Field -->
        <v-text-field
            label="Email"
            type="email"
            v-model="email"
            :rules="emailRules"
            outlined
            dense
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="action-buttons">
        <v-btn
            block
            type="submit"
            size="large"
            color="black"
            variant="elevated"
            :loading="isLoading"
            :disabled="isLoading"
            @click="submitForgotPassword"
        >{{ isLoading ? "Wysyłanie..." : "Wyslij" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import { emailPatternRule, fieldRequiredRule } from "@/utils/formValidators";

export default {
  data() {
    return {
      isSuccess: false,
      successMessage: '',
      isError: false,
      errorMessage: '',
      isLoading: false,
      email: '',
      apiUrl: process.env.VUE_APP_API_URL,
      emailRules: [
        fieldRequiredRule(),
        emailPatternRule(),
      ],
    };
  },
  watch: {
    email: 'hideApiErrors',
  },
  methods: {
    hideAlerts() {
      this.isError = false;
      this.isSuccess = false;
    },
    async submitForgotPassword() {
      this.isLoading = true;
      this.message = '';
      this.errorMessage = '';
      const form = new FormData();
      form.append('email', this.email);

      try {
        const response = await axios.post(this.apiUrl + 'forgot-password/', form);

        if (response.status === 200) {
          this.isSuccess = true;
          this.successMessage = 'Email z nowym hasłem został wysłany.';
        }
      } catch (error) {
        this.isError = true;

        if (error.response && error.response.status === 400) {
          this.errorMessage = 'Niepoprawny adres email.';
        } else {
          this.errorMessage = 'Wystąpił błąd. Spróbuj ponownie.';
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 18px 16px;
}
.forgot-password-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
}

.forgot-password-form {
  box-shadow: none;
}

.forgot-password-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.forgot-password-form .form-group {
  display: block;
  box-shadow: none;
}

.forgot-password-form .form-group input {
  width: 100%;
  padding: 10px;
}

.forgot-password-form .black-button {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
}

.message {
  color: green;
  text-align: center;
  margin-bottom: 15px;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}
</style>
