<template>
  <v-container class="alert-box-wrapper">
    <v-alert :class="{ 'show-alert': isError }" :value="true" class="alert-box" type="error">{{
        errorMessage
      }}
    </v-alert>
  </v-container>

  <v-container class="login-page">
    <v-card class="login-form" outlined>
      <v-card-title class="text-h5">Logowanie</v-card-title>
      <v-card-text>
        <!-- Email Field -->
        <v-text-field
            v-model="username"
            :rules="emailRules"
            label="Email"
            type="email"
        ></v-text-field>

        <!-- Password Field -->
        <v-text-field
            v-model="password"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="passwordRules"
            :type="isPasswordVisible ? 'text' : 'password'"
            label="Password"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="action-buttons">
        <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            block
            color="black"
            size="large"
            type="submit"
            variant="elevated"
            @click="submitLogin"
        >{{ isLoading ? "Logowanie..." : "Zaloguj się" }}
        </v-btn>

        <!-- Forgot Password Link -->
        <v-btn block class="link" color="blue" variant="text" @click="goToForgotPassword">Nie pamiętam hasła</v-btn>
      </v-card-actions>
    </v-card>
    <!-- Terms and Conditions Modal -->
    <v-dialog v-model="showTermsModal" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">Akceptacja Regulaminu</v-card-title>
        <v-card-text>
          Aby kontynuować, musisz zaakceptować nasz regulamin.
          <v-btn color="primary" @click="viewTerms">Przeczytaj Regulamin</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="acceptTerms">Akceptuję</v-btn>
          <v-btn color="error" @click="rejectTerms">Odrzucam</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import axios from 'axios';
import {emailPatternRule, fieldRequiredRule, passwordPatternRule} from '@/utils/formValidators';
import authService from "@/services/auth.service";

export default {
  data() {
    return {
      isPasswordVisible: false,
      isError: false,
      errorMessage: '',
      isLoading: false,
      username: '',
      password: '',
      apiUrl: process.env.VUE_APP_API_URL,
      emailRules: [
        fieldRequiredRule(),
        emailPatternRule(),
      ],
      passwordRules: [
        fieldRequiredRule(),
        passwordPatternRule(),
      ],
      showTermsModal: false,
      lastLoginResponse: null,
    };
  },
  watch: {
    username() {
      this.hideApiErrors();
    },
    password() {
      this.hideApiErrors();
    },
  },
  methods: {
    hideApiErrors() {
      this.isError = false;
    },
    async submitLogin() {
      this.isLoading = true;
      const form = new FormData();
      form.append('username', this.username);
      form.append('password', this.password);

      try {
        const response = await axios.post(this.apiUrl + "login/", form);
        this.lastLoginResponse = response;

        if (!response.data.newest_regulamin_accepted) {
          // Show terms and conditions modal/dialog
          this.showTermsModal = true;
        } else {
          this.handleSuccessfulLogin(response);
        }
      } catch (error) {
        this.isError = true;
        this.errorMessage = 'Wystąpił błąd. Sprawdź dane i próbuj ponownie.';
      } finally {
        this.isLoading = false;
      }
    },
    handleSuccessfulLogin(response) {
      localStorage.setItem('userToken', response.data.token);
      localStorage.setItem('userEmail', this.username);
      authService.authState.isLoggedIn = true;
      this.$router.push({name: 'dashboard'});
      this.setAutoLogout();
    },
    async acceptTerms() {
      try {
        // Call API to accept terms
        await axios.post(this.apiUrl + 'update-terms-acceptance/', {
          newest_regulamin_accepted: true
        }, {
          headers: {
            'Authorization': `Bearer ${this.lastLoginResponse.data.token}`
          }
        });
        // Handle successful login
        this.handleSuccessfulLogin(this.lastLoginResponse);
      } catch (error) {
        console.error('Error accepting terms:', error);
        this.isError = true;
        this.errorMessage = 'Wystąpił błąd podczas akceptacji regulaminu. Spróbuj ponownie.';
      } finally {
        this.showTermsModal = false;
      }
    },
    viewTerms() {
      // Open terms of service in a new tab
      window.open(this.$router.resolve({ name: 'terms-of-service' }).href, '_blank');
    },
    rejectTerms() {
      // Close the modal and show an error message
      this.showTermsModal = false;
      this.isError = true;
      this.errorMessage = 'Musisz zaakceptować regulamin, aby się zalogować.';
      // Clear the login data
      this.username = '';
      this.password = '';
      this.lastLoginResponse = null;
    },
    setAutoLogout() {
      authService.setAutoLogout();
    },
    goToForgotPassword() {
      this.$router.push({name: 'forgot-password'});
    }
  }
};
</script>

<style lang="scss" scoped>
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 18px 16px;
}

.login-page {
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
}

.login-form {
  box-shadow: none;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form .form-group {
  display: block; /* Override flex display */
}

.login-form .form-group input {
  width: 100%; /* Full width for input fields */
  padding: 10px; /* Adjust padding if needed */
}

.login-form .black-button {
  width: 100%; /* Full width for the button */
  padding: 10px 20px; /* Adjust padding if needed */
  margin-top: 10px; /* Space between buttons */
}

.login-form .forgot-password-text {
  text-align: center;
  color: black; /* Changed text color to black */
  cursor: pointer; /* Change cursor to pointer */
  font-size: 0.9em; /* Smaller font size */
  margin-top: 15px; /* Space after the login button */
}

.link {
  height: 24px;
  line-height: 150%;
  text-transform: none;
}
</style>
